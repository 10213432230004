
import { useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { defineComponent, ref, PropType } from "@vue/composition-api";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { api } from "@/api/api";
import { getInitialModalData, useOpenModal } from "@/shared/helpers/modalHelpers";
import { ApiGetCourseInvitationDto } from "@/api/generated/Api";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { setSingleCourseStorageData } from "@/shared/helpers/courseStorageHelper";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import CourseInvitationDeclineModal from "@/components/courses/section/CourseInvitationDeclineModal.vue";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { StatusType } from "@/shared/enums/courseApplicationStatus.enums";

export default defineComponent({
  name: "TheCourseInvitationList",
  components: { BaseModal, BaseModalForm, CourseInvitationDeclineModal },
  props: {
    courseInvitations: {
      type: Array as PropType<ApiGetCourseInvitationDto[]>,
      required: true,
    },
  },
  emits: ["loadCourseInvitations"],
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const router = useRouter();
    const modalData = ref(getInitialModalData());

    const openDeclineModal = useOpenModal(ModalType.Unspecified, "", modalData);

    const loadCourseInvitations = () => {
      emit("loadCourseInvitations");
    };

    const acceptCourseInvitation = async (id: number, courseId: number) => {
      await api.course.acceptInvitationAsync(id);
      openNotification(store, NotificationItemType.Success, "Invitasjon akseptert", 5000);

      setSingleCourseStorageData({
        id: courseId,
        userStatus: StatusType.Påmeldt,
      });

      router.push({
        name: "singleCourse",
        params: { id: courseId.toString(), userStatus: "påmeldt" },
      });
    };

    const getFormattedDate = (date: Date | string) => {
      return formatLocalizedDate(date, "d.M.yy");
    };

    return {
      isMobile,
      ModalType,
      modalData,
      openDeclineModal,
      acceptCourseInvitation,
      loadCourseInvitations,
      getFormattedDate,
    };
  },
});
