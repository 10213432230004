
import { api } from "@/api/api";
import { ApiGetCourseInvitationDto } from "@/api/generated/Api";
import TheCourseInvitationList from "@/components/courses/list/TheCourseInvitationList.vue";
import BaseHeaderList from "@/components/shared/header/BaseHeaderList.vue";
import BaseEmptyList from "@/components/shared/list/BaseEmptyList.vue";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { CourseInviteStatus } from "@/shared/enums/courseInviteStatus.enum";
import { isMobile } from "@/shared/helpers/displayHelpers";

export default defineComponent({
  name: "CourseInvitationListPage",
  components: { BaseHeaderList, BaseEmptyList, TheCourseInvitationList },
  setup() {
    const emptyListMessage = ref("");
    const courseInvitations = ref<ApiGetCourseInvitationDto[]>([]);

    const loadCourseInvitations = async () => {
      await globalLoadingWrapper({ blocking: true, type: LoadingType.Spinner }, async () => {
        courseInvitations.value = (await api.course.getCourseInvitationsForUser()).data?.filter(
          (invite) => invite.status === CourseInviteStatus.new
        );
      });
    };

    onMounted(async () => {
      await loadCourseInvitations();
    });

    return {
      isMobile,
      courseInvitations,
      emptyListMessage,
      loadCourseInvitations,
    };
  },
});
