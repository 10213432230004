var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "modalType": _vm.modalType,
      "headline": "Advarsel",
      "headlineIcon": "mdi-information",
      "headlineIconColor": "amber",
      "submitButtonText": "Ja",
      "closeButtonText": "Nei"
    },
    on: {
      "submit": _vm.declineCourseInvitation,
      "close": _vm.closeModal
    }
  }, [_c('v-card-text', {
    staticClass: "text--primary text-subtitle-1 text-center font-weight-medium"
  }, [_vm._v(" Er du sikker på at du vil avslå denne invitasjonen? ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }