var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "data-cy": "baseModalForm"
    }
  }, [_c('v-container', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm.headlineIcon ? _c('v-icon', {
    staticClass: "pb-1",
    attrs: {
      "color": _vm.headlineIconColor
    }
  }, [_vm._v(" " + _vm._s(_vm.headlineIcon) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.headline) + " ")], 1)]), _c('v-card-text', [_vm._t("default")], 2), _c('v-card-actions', [_c('v-spacer'), !_vm.hideCancelAndSaveButton ? _c('v-btn', {
    attrs: {
      "color": "grey darken-3",
      "data-cy": "Cancel_Button",
      "primary": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.grandParentClose();
      }
    }
  }, [_vm._v(_vm._s(_vm.closeButtonText || "Avbryt") + " ")]) : _vm._e(), !_vm.hideCancelAndSaveButton && !_vm.readonly ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "data-cy": "Save_Edit_Button"
    },
    domProps: {
      "textContent": _vm._s(_vm.submitButtonText)
    },
    on: {
      "click": _vm.submitForm
    }
  }) : _vm._e(), _vm.hideCancelAndSaveButton ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "data-cy": "Close_Button",
      "text": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.closeButtonText)
    },
    on: {
      "click": function ($event) {
        return _vm.grandParentClose();
      }
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }