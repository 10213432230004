
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "BaseModalForm",
  props: {
    headline: {
      type: String,
      default: "",
    },
    headlineIcon: {
      type: String,
      default: null,
      required: false,
    },
    headlineIconColor: {
      type: String,
      default: "",
      required: false,
    },
    submitButtonText: {
      type: String,
      default: "Lagre",
    },
    closeButtonText: {
      type: String,
      default: "Lukk",
    },
    hideCancelAndSaveButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
  },
  setup(props) {
    return {
      readonly: computed(() => props.modalType === ModalType.Display),
    };
  },
  methods: {
    submitForm() {
      this.$emit("submit");
    },
    grandParentClose() {
      // FIXME must be refactored
      this.$emit("close");
      // should never use parent, fix in any component that breaks
      // this.$parent.$emit("close");
    },
  },
});
