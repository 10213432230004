
import { api } from "@/api/api";
import { defineComponent, PropType } from "@vue/composition-api";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";

export default defineComponent({
  name: "CourseInvitationDeclineModal",
  components: { BaseModalForm },
  emits: ["close", "loadCourseInvitations"],
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    inviteId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const declineCourseInvitation = async () => {
      await api.course.declineInvitationAsync(props.inviteId);
      closeModal();
    };

    const closeModal = () => {
      emit("close");
      emit("loadCourseInvitations");
    };

    return {
      declineCourseInvitation,
      closeModal,
    };
  },
});
