import { Ref } from "@vue/composition-api";
import { TranslateResult } from "vue-i18n";
import { ModalType } from "../enums/modalTypeEnum";
import { ModalBaseData } from "../interfaces/ModalBaseData.interface";

export const useOpenModal = (
  modalType: ModalType,
  elementName: string | TranslateResult,
  modalData: Ref<ModalBaseData>
) => {
  const openModal = (selectedItem?: any, selectedItemName?: string) => {
    const getHeadline = () => {
      if (modalType === ModalType.Add) {
        return `Legg til ${elementName}`;
      }
      if (modalType === ModalType.Delete) {
        return `Slett ${selectedItemName || elementName}`;
      }
      if (modalType === ModalType.Edit) {
        return `Rediger ${selectedItemName || elementName}`;
      }
      if (modalType === ModalType.Display) {
        return selectedItemName || (elementName as string);
      }
      if (modalType === ModalType.Custom) {
        return elementName as string;
      }
    };

    modalData.value = {
      modalHeadline: getHeadline() ?? "Ukjent",
      modalType,
      showModal: true,
    };
    modalData.value.existingItem = selectedItem;
    modalData.value.existingItemId = selectedItem?.id;
  };

  return openModal;
};

export const getInitialModalData = (): ModalBaseData => ({
  modalHeadline: "",
  modalType: ModalType.Unspecified,
  showModal: false,
  existingItem: undefined,
  existingItemId: undefined,
});
