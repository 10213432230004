var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', [_c('CourseInvitationDeclineModal', {
    attrs: {
      "modalType": _vm.modalData.modalType,
      "inviteId": _vm.modalData.existingItemId
    },
    on: {
      "close": function ($event) {
        _vm.modalData.showModal = false;
      },
      "loadCourseInvitations": _vm.loadCourseInvitations
    }
  })], 1) : _vm._e(), _c('v-card', {
    staticClass: "background-primary-color mx-4",
    attrs: {
      "elevation": 0
    },
    on: {
      "update": _vm.loadCourseInvitations
    }
  }, [!_vm.isMobile ? _c('v-toolbar-title', {
    staticClass: "font-weight-medium mb-4"
  }, [_vm._v(" Kursinvitasjoner ")]) : _vm._e(), _vm.courseInvitations.length ? _c('v-row', _vm._l(_vm.courseInvitations, function (invite, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "sm": "12",
        "md": "4",
        "data-cy": "courseInvitationsCard"
      }
    }, [_c('v-card', {
      staticClass: "mx-auto font-weight-regular"
    }, [_c('v-toolbar', {
      attrs: {
        "flat": "",
        "dense": "",
        "dark": "",
        "color": "primary"
      }
    }, [_c('v-toolbar-title', {
      domProps: {
        "textContent": _vm._s(invite.course && invite.course.externalTitle)
      }
    }), _c('v-spacer'), _c('v-chip', {
      attrs: {
        "label": "",
        "outlined": "",
        "color": "white"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v("mdi-calendar-clock")]), _vm._v(" " + _vm._s(invite.course && invite.course.totalScheduledHours) + " ")], 1)], 1), _c('v-card-text', {
      staticClass: "course-invitation-card-text text-body-1 grey--text text--darken-4 pb-0"
    }, [_c('p', [_vm._v(" Kurset har en varighet på totalt " + _vm._s(invite.course && invite.course.totalScheduledHours) + " timer fra " + _vm._s(invite.course ? ((_vm.getFormattedDate(invite.course.startDate)) + " - " + (_vm.getFormattedDate(invite.course.endDate))) : "Finner ikke dato") + " ")]), invite.course && invite.course.enrollmentDeadline ? _c('v-alert', {
      attrs: {
        "outlined": "",
        "type": "info"
      }
    }, [_vm._v(" Søknadsfrist for kurset er " + _vm._s(_vm.getFormattedDate(invite.course.enrollmentDeadline)) + ". ")]) : _vm._e()], 1), _c('v-card-actions', {
      staticClass: "pt-0 pb-3 mx-2"
    }, [_c('v-spacer'), _c('v-btn', {
      staticClass: "mr-2",
      attrs: {
        "elevation": "0"
      },
      on: {
        "click": function ($event) {
          _vm.openDeclineModal(invite, invite.id.toString());
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-close-thick ")]), _c('span', {
      staticClass: "mr-2",
      attrs: {
        "data-cy": "decline-invitation"
      }
    }, [_vm._v(" Avslå ")])], 1), _c('v-btn', {
      attrs: {
        "elevation": "0",
        "color": "primary",
        "dark": ""
      },
      on: {
        "click": function ($event) {
          return _vm.acceptCourseInvitation(invite.id, invite.courseId);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-check-bold ")]), _c('span', {
      staticClass: "mr-2",
      attrs: {
        "data-cy": "accept-invitation"
      }
    }, [_vm._v(" Godta invitasjon ")])], 1)], 1)], 1)], 1);
  }), 1) : _c('v-row', {
    staticClass: "pl-3 py-5"
  }, [_vm._v(" Du har ingen kursinvitasjoner ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }