var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    class: _vm.isMobile ? 'pa-0' : '',
    attrs: {
      "fluid": ""
    }
  }, [_c('BaseHeaderList', {
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function () {
        return [_c('BaseEmptyList', {
          attrs: {
            "list": _vm.courseInvitations,
            "message": _vm.emptyListMessage
          }
        })];
      },
      proxy: true
    }])
  }), _c('TheCourseInvitationList', {
    attrs: {
      "courseInvitations": _vm.courseInvitations
    },
    on: {
      "loadCourseInvitations": _vm.loadCourseInvitations
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }