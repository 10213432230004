
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseEmptyList",
  props: {
    list: {
      type: Array,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
});
